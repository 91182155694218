<template>
  <v-footer class="py-4" dark height="auto">
    <v-container>
      <v-row class="py-0">
        <v-col class="py-0" cols="10">
          <h5>Öffnungszeiten:</h5>
          <h5>Montag - Samstag 07:00 - 17:00 Uhr</h5>
        </v-col>
        <v-spacer />
        <v-col cols="2 text-right">
          <base-btn
            class="mr-0"
            square
            title="nach oben"
            @click="$vuetify.goTo(0)">
            <v-icon>mdi-chevron-up</v-icon>
          </base-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "CoreFooter",

  data: () => ({
    items: [
      {
        href: "#!",
        icon: "mdi-twitter",
      },
      {
        href: "#!",
        icon: "mdi-instagram",
      },
      {
        href: "#!",
        icon: "mdi-facebook",
      },
      {
        href: "#!",
        icon: "mdi-google-plus",
      },
      {
        href: "#!",
        icon: "mdi-reddit",
      },
      {
        href: "#!",
        icon: "mdi-discord",
      },
      {
        href: "#!",
        icon: "mdi-pinterest",
      },
    ],
  }),
};
</script>
